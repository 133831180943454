import {configure, makeObservable, observable} from 'mobx';

export default class AllStore {
    prevPathname = '';
    prevButton = false;
    svgFlag = false;
    // Флаг взаимодействия пользователя
    @observable interactionFlag = false;
    @observable headerHeight = 56;

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;

        // Добавляем слушатели на события пользователя
        setTimeout(() => this.interactionFlag = true, 5000);
        document.addEventListener("click", this.handleUserInteraction);
        document.addEventListener("keypress", this.handleUserInteraction);
        document.addEventListener("mousemove", this.handleUserInteraction);
        document.addEventListener("mousedown", this.handleUserInteraction);
        document.addEventListener("mouseup", this.handleUserInteraction);
        document.addEventListener("dblclick", this.handleUserInteraction);
        document.addEventListener("contextmenu", this.handleUserInteraction);
        document.addEventListener("wheel", this.handleUserInteraction);
        document.addEventListener("touchstart", this.handleUserInteraction);
        document.addEventListener("touchend", this.handleUserInteraction);
        document.addEventListener("touchmove", this.handleUserInteraction);
        document.addEventListener("keydown", this.handleUserInteraction);
        document.addEventListener("keyup", this.handleUserInteraction);
        document.addEventListener("scroll", this.handleUserInteraction);
        document.addEventListener("resize", this.handleUserInteraction);
        document.addEventListener("mouseenter", this.handleUserInteraction);
        document.addEventListener("mouseleave", this.handleUserInteraction);
    }

    // Метод для обработки событий взаимодействия пользователя
    handleUserInteraction = () => {
        // Устанавливаем флаг взаимодействия пользователя в true
        this.interactionFlag = true;
    }

    setHeight(height) {
        this.headerHeight = height;
    }
}

